import React from "react";
import Head from "next/head";

type MetaType = {
  title?: string;
  keywords?: string;
  description?: string;
  url?: string;
  imgUrl?: string;
};

const defaultTitle = "Geoscanning";
const defaultDescription =
  "Jsme GEOSCANNING a přinášíme komplexní řešení na míru pro vaše potřeby.";
const defaultImgUrl = "https://www.geoscanning.cz/images/slideshow/hero.jpg";

const Meta = ({
  title = defaultTitle,
  keywords,
  description = defaultDescription,
  url,
  imgUrl = defaultImgUrl,
}: MetaType) => {
  return (
    <Head>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <link rel="icon" href="/favicon.ico" />
      <meta name="description" content={description} key="description" />
      <meta name="keywords" content={keywords} />
      <meta name="robots" content="index, follow" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
      />

      <meta
        name="twitter:card"
        content="summary_large_image"
        key="twitter:card"
      />
      <meta property="og:locale" content="cs" />
      <meta property="og:site_name" content="Geoscanning" />
      {url && <meta property="og:url" content={url} key="og:url" />}
      <meta property="og:title" content={title} key="og:title" />
      <meta
        property="og:description"
        content={description}
        key="og:description"
      />
      <meta property="og:image" content={imgUrl} key="og:image" />
      {url && <link rel="canonical" href={url} />}

      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossOrigin="true"
      />
      <link
        href="https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500;1,700&family=Roboto+Slab:wght@100;200;300;400;500;800&display=swap"
        rel="stylesheet"
      />
    </Head>
  );
};

Meta.defaultProps = {
  title: defaultTitle,
  keywords: "geoscanning, geo, scanning",
  description: defaultDescription,
  url: undefined,
  imgUrl: defaultImgUrl,
};

export default Meta;
