import { DefaultTheme } from "styled-components";

declare module "styled-components" {
  export interface DefaultTheme {
    colors: ThemeColors;
  }
}
export interface ThemeColors {
  primary: string;
  secondary: string;
  tertiary: string;
  dark: string;
  accent: string;
  white: string;
  light: string;
  lightGrey: string;
}
const colors = {
  white: "#FFFFFF",
  light: "#F4F7F6",
  lightGrey: "#757575",
  black: "#000000",
  darkPurple: "#1A1F39",
  purple: "#409",
  lightPurple: "#A7A4E0",
  lightBlue: "#EBF2FC",
  yellow: "#BBA600",
};

export const lightTheme: DefaultTheme = {
  colors: {
    primary: colors.purple,
    secondary: colors.lightPurple,
    tertiary: colors.lightBlue,
    dark: colors.darkPurple,
    accent: colors.yellow,
    white: colors.white,
    light: colors.light,
    lightGrey: colors.lightGrey,
  },
};
