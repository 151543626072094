import { createContext } from "react";
import { CategoryModel, Model } from "../types/models";

interface Context {
  isHomepage: boolean;
  isHeaderNavOpen: boolean;
  setIsHeaderNavOpen: (open: boolean) => void;
  categories: Model<CategoryModel>[];
}

export const GlobalContext = createContext<Context>({
  isHomepage: true,
  isHeaderNavOpen: false,
  setIsHeaderNavOpen: (open: boolean) => {},
  categories: [],
});
