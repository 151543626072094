import type { AppProps } from "next/app";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "../globalStyles";
import { Page } from "../types/page";
import DefaultLayout from "../components/Global/Layout/Layout";
import { lightTheme } from "../themeConfig";
import React, { useState } from "react";
import { useRouter } from "next/router";
import { GlobalContext } from "../helpers/context";
import dynamic from "next/dynamic";
import "../components/Shared/ProgressBar/progressBar.css";
import axios from "axios";
import logger from "../services/logger";
import { CategoryModel, Model } from "../types/models";

const ProgressBar = dynamic(
  () => import("../components/Shared/ProgressBar/ProgressBar"),
  { ssr: false }
);

// this should give a better typing
type Props = AppProps & {
  Component: Page;
  props: {
    categories: Model<CategoryModel>[];
  };
};

function App({ Component, pageProps, props }: Props) {
  const router = useRouter();
  const [isHeaderNavOpen, setIsHeaderNavOpen] = useState(false);
  const Layout = Component.layout ?? DefaultLayout;

  return (
    <GlobalContext.Provider
      value={{
        isHomepage: router.route === "/",
        isHeaderNavOpen,
        setIsHeaderNavOpen,
        categories: props?.categories || [],
      }}
    >
      <ThemeProvider theme={lightTheme}>
        <GlobalStyles />
        <ProgressBar />
        <Layout {...pageProps}>
          <Component {...pageProps} />
        </Layout>
      </ThemeProvider>
    </GlobalContext.Provider>
  );
}

App.getInitialProps = async () => {
  try {
    const categoriesResponse = await axios.get(
      `${process.env.BACKEND_API_URL}/categories`
    );
    const categories = await categoriesResponse.data?.data;

    return {
      props: { categories },
    };
  } catch (e) {
    logger.error(e);

    return {
      props: { categories: [] },
    };
  }
};

export default App;
