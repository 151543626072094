import styled from "styled-components";
import { sizePx } from "../../../utils/devices";
import { Container } from "../../../globalStyles";
import Button from "../../Shared/Button/Button";

export const FooterContainer = styled.footer`
  background: ${({ theme }) => theme.colors.light};
  color: white;
  z-index: 20;
  position: relative;
`;

export const ContentWrapper = styled.div`
  background: ${({ theme }) => theme.colors.dark};
  margin-top: -5px;
`;

export const ContentContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  padding: 5rem 50px;
  @media (max-width: ${sizePx.tablet}) {
    text-align: center;
  }
`;

export const FooterLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-basis: 30%;
  @media (max-width: ${sizePx.tablet}) {
    flex-basis: 100%;
    align-items: center;
  }
`;

export const FooterHeading = styled.h2`
  margin: 0;
`;

export const FooterSubHeading = styled.h3`
  font-family: "Roboto Slab", serif;
  font-style: italic;
  font-weight: 300;
  margin: 0.5rem 0 2.5rem;
  @media (max-width: ${sizePx.tablet}) {
    margin-bottom: 1rem;
  }
`;

export const FooterButton = styled(Button)``;

export const FooterRightContainer = styled.div`
  display: flex;
  justify-content: space-around;
  flex-basis: 70%;
  @media (max-width: ${sizePx.tablet}) {
    margin-top: 2rem;
    flex-basis: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

export const FooterMenuGroup = styled.div`
  display: flex;
  flex-direction: column;
  @media (max-width: ${sizePx.tablet}) {
    margin: 1.5rem 0;
    align-items: center;
  }
`;

export const FooterMenuHeading = styled.h4`
  margin-bottom: 0.5rem;
`;

export const FooterMenuItem = styled.span`
  margin: 0.2rem 0;
  font-weight: 300;
  & a {
    color: white;
    &:hover {
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }
`;

export const Copyright = styled.div`
  margin-top: 3rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
`;

export const CopyrightItem = styled.span`
  text-transform: uppercase;
  font-size: 0.7rem;
  color: ${({ theme }) => theme.colors.lightGrey};
  //&:nth-child(2) {
  //  margin: 0 3%;
  //}
  @media (max-width: ${sizePx.tablet}) {
    margin: 3% 0;
    text-align: center;
    flex-basis: 30%;
    flex-grow: 1;
  }
`;

export const Credits = styled(CopyrightItem)`
  color: white;
  margin-left: auto;
  @media (max-width: ${sizePx.laptop}) {
    text-align: center;
    flex-basis: 100%;
    margin-top: 2rem;
  }
  a {
    display: flex;
    color: white;
    align-items: center;
    @media (max-width: ${sizePx.laptop}) {
      justify-content: center;
    }
    &:hover {
      color: ${({ theme }) => theme.colors?.secondary};
    }
  }
`;
