import * as Sentry from "@sentry/nextjs";

const logger = {
  error: (error: any, extra: any = null) => {
    Sentry.withScope((scope) => {
      extra && scope.setExtras(extra);
      Sentry.captureException(error);
    });
  },
};

export default logger;
