import React, { useContext } from "react";
import {
  ContentContainer,
  ContentWrapper,
  Copyright,
  CopyrightItem,
  Credits,
  FooterButton,
  FooterContainer,
  FooterHeading,
  FooterLeftContainer,
  FooterMenuGroup,
  FooterMenuHeading,
  FooterMenuItem,
  FooterRightContainer,
  FooterSubHeading,
} from "./Footer.elements";
import { Svg } from "../../Shared/Svg/Svg";
import { BsCodeSlash } from "react-icons/bs";
import Link from "next/link";
import { CategoryModel, Model } from "../../../types/models";
import { GlobalContext } from "../../../helpers/context";

const Wave = () => (
  <Svg viewBox="37 72 740 110" width="100%">
    <path
      fill="#1A1F39"
      d="M 9.23019 181.92295986681435 L 780.76818 181.68282454996123 L 779.99895 78.46142612290974 C 715.3834514419957 162.44180895961858 416.15289872475705 163.969946100009 9.23019 181.92295986681435 z"
      id="svg_5"
    ></path>
  </Svg>
);

const Footer = () => {
  const { categories } = useContext(GlobalContext);

  return (
    <FooterContainer>
      <Wave />
      <ContentWrapper>
        <ContentContainer>
          <FooterLeftContainer>
            <FooterHeading>Komplexní řešení</FooterHeading>
            <FooterSubHeading>
              postavené na ověřených technologiích
            </FooterSubHeading>
            <FooterButton href={"/kontakt"}>Kontakt</FooterButton>
          </FooterLeftContainer>

          <FooterRightContainer>
            <FooterMenuGroup>
              <FooterMenuHeading>Řešení</FooterMenuHeading>
              {categories.map((category, key) => (
                <FooterMenuItem key={key}>
                  <Link href={category?.attributes.url || "/"} passHref>
                    {category?.attributes.title}
                  </Link>
                </FooterMenuItem>
              ))}
            </FooterMenuGroup>

            {/*<FooterMenuGroup>*/}
            {/*  <FooterMenuHeading>Technologie</FooterMenuHeading>*/}
            {/*  <FooterMenuItem>3D mobilní skenování</FooterMenuItem>*/}
            {/*  <FooterMenuItem>3D terestrický sken</FooterMenuItem>*/}
            {/*  <FooterMenuItem>3D ruční skener</FooterMenuItem>*/}
            {/*  <FooterMenuItem>Multibeam echo sonář</FooterMenuItem>*/}
            {/*  <FooterMenuItem>Digitální tvrdoměr</FooterMenuItem>*/}
            {/*</FooterMenuGroup>*/}

            <FooterMenuGroup>
              <FooterMenuHeading>GeoScanning s r.o.</FooterMenuHeading>
              <FooterMenuItem>Cyrilská 7, 60200 Brno</FooterMenuItem>
              <FooterMenuItem>info@geoscanning.cz</FooterMenuItem>
              <FooterMenuItem>+420 601 022 023</FooterMenuItem>
            </FooterMenuGroup>
          </FooterRightContainer>

          <Copyright>
            <CopyrightItem>
              © {new Date().getFullYear()} GEOSCANNING. VŠECHNA PRÁVA JSOU
              VYHRAZENA
            </CopyrightItem>
            {/*<CopyrightItem>Zásady ochrany osobních údajů</CopyrightItem>*/}
            {/*<CopyrightItem>podmínky použití</CopyrightItem>*/}
            {/*<Credits>*/}
            {/*  <a*/}
            {/*    href="https://matejfarkas.com"*/}
            {/*    rel="noreferrer"*/}
            {/*    target="_blank"*/}
            {/*  >*/}
            {/*    <BsCodeSlash size={16} style={{ margin: "0 0.5rem" }} />*/}
            {/*    by Matej Farkaš*/}
            {/*  </a>*/}
            {/*</Credits>*/}
          </Copyright>
        </ContentContainer>
      </ContentWrapper>
    </FooterContainer>
  );
};

export default Footer;
