import styled, { css } from "styled-components";
import { Props as ButtonProps } from "./Button";

const style = css<ButtonProps>`
  background: ${({ bgColor, inverted }) =>
    inverted ? "white" : bgColor || "transparent"};
  color: ${({ theme, inverted }) => (inverted ? "black" : theme.colors.white)};
  padding: 10px 30px;
  text-align: center;
  display: inline-block;
  border-radius: 30px;
  border: 1px solid rgba(255, 255, 255, 1);
  transition: 0.25s;
  font-size: 0.8rem;

  &:hover {
    cursor: pointer;
    background-color: ${({ theme, bgColorHover }) =>
      bgColorHover || theme.colors.white};
    color: ${({ theme, colorHover }) =>
      colorHover ? colorHover : theme.colors.dark};
  }
`;

export const ButtonLinkStyled = styled.a<ButtonProps>`
  ${style}
`;

export const ButtonStyled = styled.button<ButtonProps>`
  ${style}
`;
