import styled from "styled-components";
import { Container } from "../../../globalStyles";
import { sizePx } from "../../../utils/devices";

export const HeaderWrapper = styled.div<{ isHomepage?: boolean }>`
  position: relative;
  ${({ isHomepage }) => !isHomepage && `background: white;`}
  ${({ isHomepage }) => !isHomepage && `box-shadow: 0 5px 9px 3px #e7e7e7;`}
  ${({ isHomepage }) =>
    isHomepage && `margin-bottom:-100px;`} //CSS HASC FOR SAFARI
  z-index: 40;
`;

export const HeaderContainer = styled(Container)<{ isHomepage?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100px;
  max-width: 1600px;
  z-index: 10;
  @media (max-width: ${sizePx.tablet}) {
    flex-direction: row;
    justify-content: space-between;
    ${({ isHomepage }) => isHomepage && `margin-bottom: -100px;`}
    padding: 0 10%;
  }
`;

export const LogoLink = styled.a`
  font-size: 3em;
  text-decoration: none;
  z-index: 30;
  align-content: center;
  @media (max-width: ${sizePx.tablet}) {
    display: flex;
    align-items: center;
    max-width: 80%;
  }
`;
