import React, { PropsWithChildren, ReactNode } from "react";
import Link from "next/link";
import { ButtonLinkStyled, ButtonStyled } from "./Button.elements";

export type Props = {
  href?: string;
  color?: string;
  colorHover?: string;
  bgColor?: string;
  bgColorHover?: string;
  inverted?: boolean;
  onClick?: () => void;
};

const Button = (props: PropsWithChildren<Props>) => {
  return props.href ? (
    <Link href={props.href} passHref>
      <ButtonLinkStyled {...props}>{props.children}</ButtonLinkStyled>
    </Link>
  ) : (
    <ButtonStyled {...props}>{props.children}</ButtonStyled>
  );
};

export default Button;
