import React, { useContext } from "react";
import { HeaderContainer, HeaderWrapper, LogoLink } from "./Header.elements";
import Link from "next/link";
import Image from "next/image";
import HeaderNav from "../HeaderNav/HeaderNav";
import { GlobalContext } from "../../../helpers/context";

const Header = () => {
  const { isHomepage } = useContext(GlobalContext);
  const color = isHomepage ? "light" : "dark";

  return (
    <HeaderWrapper isHomepage={isHomepage}>
      <HeaderContainer isHomepage={isHomepage}>
        <Link href="/" passHref>
          <LogoLink>
            <Image
              src={`/images/logo-${color}.svg`}
              width="324"
              height="32"
              alt="geoscanning"
              priority={true}
            />
          </LogoLink>
        </Link>

        <HeaderNav color={color} />
      </HeaderContainer>
    </HeaderWrapper>
  );
};

export default Header;
