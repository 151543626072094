import styled, { createGlobalStyle } from "styled-components";
import { sizePx } from "./utils/devices";

export const GlobalStyles = createGlobalStyle`
  html, body {
    height: 100%;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
    box-sizing: border-box;
  }

  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  img {
    max-width: 100%;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  body {
    font-family: Ubuntu, Helvetica, Arial, Roboto, sans-serif;
    transition: all 0.50s linear;
    line-height: 1;
  }

  ul {
    list-style: none;
  }

  p {
    margin-bottom: 0.5rem;
    line-height: 1.25rem;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Poppins', Arial, Roboto, sans-serif;
    font-weight: 500;
  }

  h1 {
    font-size: 2em;
    margin: 1rem 0;
    line-height: 2.5rem;
    @media (max-width: ${sizePx.tablet}) {
      font-size: clamp(1.5rem, 4vh, 2rem);
    }
  }

  h2 {
    font-size: 1.5em;
    margin: 0.8rem 0;
    line-height: 2rem;
    @media (max-width: ${sizePx.tablet}) {
      font-size: clamp(1.17rem, 3vh, 1.5em);
    }
  }

  h3 {
    font-size: 1.17em;
    margin: 0.6rem 0;
    line-height: 1.5rem;
    @media (max-width: ${sizePx.tablet}) {
      font-size: clamp(.83rem, 2vh, 1.17rem);
    }
  }

  h5 {
    font-size: .83em;
    margin: 0.4rem 0;
    line-height: 1.2rem;
    @media (max-width: ${sizePx.tablet}) {
      font-size: clamp(.75rem, 1.5vh, .83em);
    }
  }

  h6 {
    font-size: .75em;
    margin: 0.2rem 0;
    line-height: 1rem;
    @media (max-width: ${sizePx.tablet}) {
      font-size: clamp(0.5rem, 1vh, .75rem);
    }
  }

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.primary};
    transition: 0.2s;

    &:hover {
      color: ${({ theme }) => theme.colors.secondary};
    }
  }

  blockquote {
    overflow: hidden;
    padding-right: 1.5em;
    padding-left: 1.5em;
    margin-left: 0;
    margin-right: 0;
    font-style: italic;
    border-left: 5px solid #ccc;
    margin-bottom: 1rem;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: 100%;
    border: 1px double #b3b3b3;
  }

  .table {
    margin: 0.9em auto;
    display: block;
    width: 100%;
    overflow-x: auto
  }

  td {
    min-width: 2em;
    padding: 0.4em;
    border: 1px solid #bfbfbf;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 50px;
`;

export const WYSIWYG = styled.div`
  ul {
    margin-bottom: 1rem;
    li {
      list-style: inside;
      padding: 0.15rem;
    }
  }
  ol {
    margin-bottom: 1rem;
    padding-left: 20px;
    li {
      list-style: decimal;
      padding: 0.15rem;
    }
  }
`;
