import styled, { css } from "styled-components";
import { sizePx } from "../../../utils/devices";
import { AiOutlineCloseCircle, AiOutlineMenu } from "react-icons/ai";
import { ThemeColors } from "../../../themeConfig";

const underline = css`
  background: ${({ theme }) => theme.colors.primary};
  content: "";
  height: 3px;
  position: absolute;
  bottom: -10px;
  width: 100%;

  @media (max-width: ${sizePx.tablet}) {
    width: 80%;
  }
`;

export const HeaderNavList = styled.ul<{ isOpen: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  z-index: 30;
  transition: transform 0.3s ease-in-out;

  @media (max-width: ${sizePx.tablet}) {
    transform: ${({ isOpen }) =>
      isOpen ? "translateX(0)" : "translateX(100%)"};
    align-items: center;
    flex-direction: column;
    justify-content: center;
    background: #110e0e;
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 30;
  }
`;

export const HeaderNavSubList = styled.ul`
  position: absolute;
  background: rgb(150 150 150 / 30%);
  display: none;

  @media (max-width: ${sizePx.tablet}) {
    position: relative !important;
    display: block;
    max-height: 0;
    margin-bottom: -10px;
    transition: 0.5s;
    transform-origin: top;
    transform: scaleY(0);
    font-size: 1.2rem;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    z-index: 10;
    background: none;
  }
`;

export const HeaderNavItem = styled.li<{ color?: "light" | "dark" }>`
  color: ${({ theme, color }) => theme.colors?.[color || "dark"]};
  margin: 0 15px;
  font-family: "Poppins", Helvetica, sans-serif;
  font-weight: bold;
  user-select: none;

  &:hover ${HeaderNavSubList} {
    display: block;
    transform: scaleY(1);
    @media (max-width: ${sizePx.tablet}) {
      margin-top: 2rem;
      max-height: 500px;
    }
  }

  @media (max-width: ${sizePx.tablet}) {
    font-size: 1.5rem;
    text-align: center;
    margin: 1rem;
    color: ${({ theme }) => theme.colors.light};
  }
`;

export const HeaderNavLink = styled.a<{
  isActive?: boolean;
  color?: "light" | "dark";
}>`
  color: ${({ theme, color }) => theme.colors?.[color || "dark"]};
  position: relative;
  display: flex;
  justify-content: center;

  &::after {
    ${({ isActive }) => isActive && underline}
  }
  &:hover {
    &::after {
      ${underline}
    }
  }
  @media (max-width: ${sizePx.tablet}) {
    color: ${({ theme }) => theme.colors.light};
  }
`;

export const HeaderNavSubItem = styled.li`
  margin: 10px 0;
  padding: 5px 10px;
`;

export const HeaderNavSubLink = styled.a<{
  isActive?: boolean;
  color?: "light" | "dark";
}>`
  color: ${({ theme, color }) => theme.colors?.[color || "dark"]};
  position: relative;
  display: flex;
  font-size: 0.9em;

  @media (max-width: ${sizePx.tablet}) {
    color: ${({ theme }) => theme.colors.light};
    justify-content: center;
    &::after {
      ${({ isActive }) => isActive && underline}
    }
  }
`;

export const HeaderMenuIconOpen = styled(AiOutlineMenu)<{
  color?: keyof ThemeColors;
}>`
  z-index: 35;
  color: ${({ color, theme }) => theme.colors?.[color || "light"]};
  height: 30px;
  width: 30px;
  display: none;
  @media (max-width: ${sizePx.tablet}) {
    display: inline;
  }
`;

export const HeaderMenuIconClose = styled(AiOutlineCloseCircle)`
  z-index: 30;
  color: white;
  height: 40px;
  width: 40px;
  display: none;
  @media (max-width: ${sizePx.tablet}) {
    position: fixed;
    right: 10%;
    display: inline;
  }
`;
