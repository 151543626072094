import Link from "next/link";
import {
  HeaderMenuIconClose,
  HeaderMenuIconOpen,
  HeaderNavItem,
  HeaderNavLink,
  HeaderNavList,
  HeaderNavSubList,
  HeaderNavSubItem,
  HeaderNavSubLink,
} from "./HeaderNav.elements";
import React, { useContext } from "react";
import { useRouter } from "next/router";
import { GlobalContext } from "../../../helpers/context";

interface Props {
  color?: "light" | "dark";
}

const data: { title: string; url: string; items: any[] }[] = [
  {
    title: "Domov",
    url: "/",
    items: [],
  },
  {
    title: "Řešení",
    url: "",
    items: [],
  },
  {
    title: "Kontakt",
    url: "/kontakt",
    items: [],
  },
];
const HeaderNav = ({ color }: Props) => {
  const router = useRouter();
  const { categories, isHeaderNavOpen, setIsHeaderNavOpen } =
    useContext(GlobalContext);
  data[1].items = categories.map((category) => category.attributes);

  const handleOpen = () => {
    setIsHeaderNavOpen(!isHeaderNavOpen);
  };

  return (
    <>
      <HeaderNavList isOpen={isHeaderNavOpen}>
        {data.map((item, key) => (
          <HeaderNavItem key={key} color={color}>
            {item.url ? (
              <Link href={item.url} passHref>
                <HeaderNavLink
                  isActive={item.url === router.asPath}
                  color={color}
                  onClick={handleOpen}
                >
                  {item.title}
                </HeaderNavLink>
              </Link>
            ) : (
              item.title
            )}

            {item.items.length > 0 && (
              <HeaderNavSubList>
                {item.items.map((item, key) => (
                  <HeaderNavSubItem key={key}>
                    <Link href={item.url} passHref>
                      <HeaderNavSubLink
                        isActive={item.url === router.asPath}
                        color={color}
                        onClick={handleOpen}
                      >
                        {item.title}
                      </HeaderNavSubLink>
                    </Link>
                  </HeaderNavSubItem>
                ))}
              </HeaderNavSubList>
            )}
          </HeaderNavItem>
        ))}
      </HeaderNavList>
      {!isHeaderNavOpen && (
        <HeaderMenuIconOpen onClick={handleOpen} color={color} />
      )}
      {isHeaderNavOpen && <HeaderMenuIconClose onClick={handleOpen} />}
    </>
  );
};

export default HeaderNav;
